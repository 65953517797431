import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";

import theme from "../constants/theme";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { SubHeader, StandOutSection, CTASection } from "../components/blocks";

export default function businessPageTemplate(props) {
  const siteMetaData = props.data.site.siteMetadata;
  const siteData = props.data.allSanitySiteConfig.edges[0].node;
  const sharedData = props.data.allSanitySharedSections.edges[0].node;
  const pageData = props.data.allSanityWhatBusinessPage.edges[0].node;

  return (
    <Layout
      locale={props.pageContext.locale}
      siteMetaData={siteMetaData}
      mainNav={siteData.mainNav}
      alternateNav={siteData.alternateNav}>
      <SEO title={pageData.pageTitle?.localized} />
      <SubHeader
        title={pageData.header?.title?.localized}
        subTitle={pageData.header?.description?.localized}
      />
      <StandOutSection
        title={pageData.textBlockSection1?.leadText?.title?.localized}
        descriptionRichText={pageData.textBlockSection1?.leadText?.descriptionRichText ?? {}}
        coverImageUrl={pageData.textBlockSection1?.mainImage?.asset?.fluid?.src}
      />
      <StandOutSection
        title={pageData.textBlockSection2?.leadText?.title?.localized}
        descriptionRichText={pageData.textBlockSection2?.leadText?.descriptionRichText ?? {}}
        coverImageUrl={pageData.textBlockSection2?.mainImage?.asset?.fluid?.src}
        backgroundColor={theme.colorsTw.bg_bg2}
        isFlipped={true}
      />
      <CTASection
        title={sharedData.callToActionSection?.title?.localized}
        description={sharedData.callToActionSection?.description?.localized}
        callToActionTitle={sharedData.callToActionSection?.callToAction?.linkTitle?.localized}
        callToActionRoute={sharedData.callToActionSection?.callToAction?.route?.slug?.current}
      />
    </Layout>
  );
}

import * as _ from "./fragments";

export const query = graphql`
  query($locale: String) {
    # Site Meta Data
    site {
      ...SiteMetaData
    }
    # Site
    allSanitySiteConfig {
      edges {
        node {
          ...MainNav
          ...AlternateNav
        }
      }
    }
    # Shared
    allSanitySharedSections {
      edges {
        node {
          ...CallToActionSection
        }
      }
    }
    # Page
    allSanityWhatBusinessPage {
      edges {
        node {
          pageTitle {
            localized(locale: $locale)
          }
          header {
            ...SubHeader
          }
          bulletsList {
            ...BulletsSection
          }
          textBlockSection1 {
            ...TextBlockSection
          }
          textBlockSection2 {
            ...TextBlockSection
          }
        }
      }
    }
  }
`;
